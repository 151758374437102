class FadeInChildren {
  constructor(el) {
    gsap.registerPlugin(ScrollTrigger)
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
    const translate = el.dataset.translate && el.dataset.translate === 'false' ? false : true
    const stagger = el.dataset.stagger ? +el.dataset.stagger : 0.1

    const tl = gsap.timeline({
      scrollTrigger: { trigger: el, start: 'top 75%' },
      defaults: { duration: 1.5, ease: 'power2.out' }
    })
      .from(el.children, { opacity: 0, stagger })

    if (!prefersReducedMotion && translate) {
      tl.from(el.children, { y: 10, stagger }, 0)
    }
    
    tl.set(el.children, { clearProps: true })
  }
}
