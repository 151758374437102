class Hero {
  constructor(el) {
    gsap.registerPlugin(ScrollTrigger)

    const elImage = el.querySelector('.hero-facelift__image__inner')
    const elHeading = el.querySelector('.hero-facelift__heading')

    const mm = gsap.matchMedia()

    mm.add('(prefers-reduced-motion: no-preference)', () => {
      gsap.fromTo(
        elHeading,
        { opacity: 0 },
        { opacity: 1, duration: 2, ease: 'sine.out' }
      )

      gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
        defaults: { ease: 'none' },
      })
        .to(elImage, { y: () => window.innerHeight * 0.2 })
    })
  }
}
