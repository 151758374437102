class StatementText {
  constructor(el) {
    const elText = el.querySelector('.statement-text__text')
    const splitWords = elText.textContent.trim().split(' ').map((word) => `<span>${word}</span>`)
    elText.innerHTML = splitWords.join(' ')

    gsap.registerPlugin(ScrollTrigger)

    gsap.timeline({
      scrollTrigger: {
        trigger: elText,
        start: 'top 75%',
        end: 'center center',
        scrub: 1,
      },
    })
      .from(elText.children, { opacity: 0.2, stagger: 0.01, ease: 'none', duration: 0.03 })
  }
}
