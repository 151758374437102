var waypointAnimTriggers = (function($) {
    var self = this;
    
    var settings = {
        container: '.js-waypointanimtrigger'
    };

    var init = function() {
        _waypointanimtriggers();
    };

    var _waypointanimtriggers = function() {

        var waypoints = $(settings.container).waypoint({
            handler: function(direction) {
                if(direction=="down") {
                    $(this.element).addClass("js-waypointanimtrigger--triggered");
                }
            },
            offset: function() {
                //return -this.element.clientHeight
                //console.log(this.element.getAttribute("data-waypoint-offset"));
                //return this.element.getAttribute("data-waypoint-offset");
                return $(window).height() - 150;
            }
        });
        
    };

     return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    waypointAnimTriggers.init();
});