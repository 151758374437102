
var projectsFilters = (function($) {
    'use strict';

    var _bind = function() {

      if(GetURLParameter('filter') != undefined) {
          $('[data-filters-intro="' + GetURLParameter('filter') + '"]').addClass('active');
        }


        $('.js-project-filters .button-filter').click(function(e) {
            e.preventDefault();
            $('.js-project-filters .button').removeClass('active');
            $(this).addClass('active');
            var filter = $(this).attr('href');
            $(".js-projects-listing").addClass('active');
            $(".js-projects-loading").addClass('active');
            $('[data-filters-intro]').removeClass('active');
            $('[data-filters-intro="'+filter+'"]').addClass('active');

            console.log('filter');

            if(filter != 'uk') {
                $('.international-video').addClass("active");
              }

              if(filter != 'international') {
                  $('.international-video').removeClass("active");
                }

              $.get("/projects-loadmore/?filter="+filter,function(data){
                  var elems = $.parseHTML( data );
                  console.log(elems);
                  if (elems.length == 0) {
                      return;
                  }
                  $(".js-projects-listing").html("");
                  $(".js-projects-listing").append(data);
              },'html')
              .done(function() {
                  $(".js-projects-loading").removeClass('active');
                  $(".js-projects-listing").removeClass('active');

                  if(window.history) {
                    history.pushState('');
                  }
              })
              .fail(function() {
                  console.log( "There was an issue connecting to the server. Sorry!" );
                  
              });




        });
    };

    function GetURLParameter(sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split('&');
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
          return sParameterName[1];
        }
      }
    }​

    function init() {
        _bind();
    }

    return {
        init:init
    };
}(jQuery));

jQuery(document).ready(function($) {
    projectsFilters.init();
});
