class Statistic {
  constructor(el) {
    const elNumber = el.querySelector('.statistic__number')
    const elLabel = el.querySelector('.statistic__label')

    const animateStatistic = el.dataset.animateStatistic === '1'
    const finalNumber = +elNumber.textContent
    const number = { value: 0 }

    if (animateStatistic) {
      elNumber.textContent = number.value
    }

    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      scrollTrigger: { trigger: el, start: 'top 75%' },
      defaults: { duration: 1.5, ease: 'power2.out' }
    })
      .from([elNumber, elLabel], { opacity: 0, stagger: 0.2 })

    if (animateStatistic) {
      tl.to(number, {
        value: finalNumber,
        onUpdate: () => {
          elNumber.textContent = Math.floor(number.value)
        }
      }, 0)
    }
  }
}
