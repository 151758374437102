class Accordion {
  constructor(el) {
    this.elItems = Array.from(el.querySelectorAll('.accordion__item'))
    this.activeIndex = this.elItems.findIndex(elItem => elItem.getAttribute('open') !== undefined)
    this.ease = 'power2.inOut'
    this.duration = 0.5

    this.elItems.forEach(element => {
      const elToggle = element.querySelector('.accordion__item__heading')
      elToggle.addEventListener('click', (e) => this.onClickItem(e, element))
    })
  }

  onClickItem(e, element) {
    e.preventDefault()

    if (this.activeIndex === this.elItems.indexOf(element)) {
      this.closeItem(this.activeIndex).then(() => {
        element.removeAttribute('open')
        this.activeIndex = -1
      })
    } else {
      if (this.elItems[this.activeIndex]) {
        const prevIndex = this.activeIndex
        this.closeItem(prevIndex).then(() => {
          this.elItems[prevIndex].removeAttribute('open')
        })
      }

      element.setAttribute('open', '')
      this.activeIndex = this.elItems.indexOf(element)
      this.openItem(this.activeIndex)
    }
  }

  openItem(index) {
    const elBody = this.elItems[index].querySelector('.accordion__item__body')
    const reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    if (reduceMotion) {
      return gsap.from(elBody, { opacity: 0 })
    }

    const bodyHeight = elBody.querySelector('.accordion__item__body__inner').offsetHeight
    return gsap.timeline()
      .fromTo(
        elBody,
        { height: 0, opacity: 0 },
        { height: bodyHeight, opacity: 1, ease: this.ease, duration: this.duration }
      )
      .set(elBody, { clearProps: true })
  }

  closeItem(index) {
    const reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    if (reduceMotion) {
      return Promise.resolve()
    }

    const elBody = this.elItems[index].querySelector('.accordion__item__body')
    return gsap.timeline()
      .to(
        elBody,
        { height: 0, opacity: 0, ease: this.ease, duration: this.duration }
      )
      .set(elBody, { clearProps: true })
  }
}
