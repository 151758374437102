/* Requires: slick.js */

var slickCarousel = (function($) {
    var self = this;

    var settings = {
        container: '.js-slick',
        gallery_tabs: '.js-slick-tabs',
        cms_carousel: '.article-carousel',
        fadecarousel: true
    };

    var init = function() {

      if((/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
        settings.fadecarousel = false;
      }

        if (settings.container.length > 0) {
            _slickcarousel();
        }

        if (settings.cms_carousel.length > 0) {
            _CMScarousel();
        }
    };

    var _slickcarousel = function() {



        $(settings.container).slick({
            speed: 300,
            arrows: true,
            dots: true,
            fade: settings.fadecarousel,
            prevArrow: '<div class="slick-prev"></div>',
            nextArrow: '<div class="slick-next"></div>'
        });

        var video = $(settings.container+' .slick-active').find('video').get(0);

        if(video) {
          video.play();
        }

        $(settings.container).on('afterChange', function(event, slick, currentSlide, nextSlide) {
          video = $(settings.container+' .slick-slide').find('video').get(0);
          if(video) {
            video.pause();
          }
          video = $(settings.container+' .slick-active').find('video').get(0);
          if(video) {
            video.play();
          }
        });

        $(settings.gallery_tabs).slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: $(settings.container),
            arrows: false,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1
                  }
                }
            ]
        });
    };

    var _CMScarousel = function() {
        $(settings.cms_carousel).each(function(index, el) {
            $(el).slick({
                speed: 100,
                cssEase: true,
                arrows: true,
                dots: false,
                prevArrow: '<div class="slick-prev"></div>',
                nextArrow: '<div class="slick-next"></div>',
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                      breakpoint: 1025,
                      settings: {
                        slidesToShow: 2
                      }
                    },
                    {
                      breakpoint: 769,
                      settings: {
                        slidesToShow: 1
                      }
                    }
                ]
            });
        });
    };

     return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    slickCarousel.init();
});
