/* Requires: skrollr.js */

var parralax = (function($) {
    var self = this;
    
    var settings = {
        container: '.parralaxme'
    };

    var init = function() {

    	if (settings.container.length < 1) return;

        _parralaxRules();
    };

    var _parralaxRules = function() {
    	if(!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
				var s = skrollr.init({forceHeight: false, documentElement : false});
		} else {
			//$("#our-beers").addClass("no-skroll");
		}
    };

     return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    parralax.init();
});