$('.js-header-search-toggle').click(function(e) {
  e.preventDefault();
  // alert("!");
  $('.hub-header').toggleClass("show-search");
  $('.hub-navigation-search__search-field').focus();

  // $('.hub-navigation-search').toggle("active");
  // $('.header-search').toggle("hidden-hub");
  // $('.hub-navigation-search__cross').toggle("hidden-hub");
});
