/* Requires: jquery.scrollify.js */

var burgerNav = (function ($) {
    var self = this;

    var settings = {
        container: '.navbar__burger'
    };

    var init = function () {
        _burgernav();
        _navOffTop();
    };

    var _burgernav = function () {
        const elNav = document.querySelector('.navbar__menu');
        const elBurger = document.querySelector('.navbar__burger');

        const toggleActiveClass = () => {
            $('.navbar__burger, .navbar__menu, body').toggleClass('active');
        };

        const removeActiveClass = () => {
            $('.navbar__burger, .navbar__menu, body').removeClass('active');
        };

        $(settings.container).on('click', function (e) {
            e.preventDefault();
            toggleActiveClass();
        });

        document.addEventListener('click', (e) => {
            if (!elNav.contains(e.target) && !elBurger.contains(e.target)) {
                removeActiveClass();
            }
        });

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                removeActiveClass();
            }
        });
    };
    var _navOffTop = function () {
        var ScrollTop = 10;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st <= ScrollTop) {
                $('body').removeClass('downscroll');
            } else {
                $('body').addClass('downscroll');
            }
            // lastScrollTop = st;
        });
    };

    return {
        init: init
    };

})(jQuery);

$(document).ready(function ($) {
    burgerNav.init();
});