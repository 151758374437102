/*
  Automatically instantiates modules based on data-attributes
  specifying module file-names.
*/

function instantiateModules(el) {
  if (!el.querySelectorAll) {
    return
  }

  const modules = {
    hero: Hero,
    statementtext: StatementText,
    cardcarousel: CardCarousel,
    fadeinchildren: FadeInChildren,
    accordion: Accordion,
    statistic: Statistic,
  }

  const moduleElements = Array.from(el.querySelectorAll('[data-module]'))

  if (el.getAttribute && el.getAttribute('data-module')) {
    moduleElements.push(el)
  }

  for (var i = 0; i < moduleElements.length; i++) {
    const el = moduleElements[i]
    const name = el.getAttribute('data-module')
    const Module = modules[name]
    if (Module) {
      new Module(el)
    }
  }
}

document.addEventListener('DOMContentLoaded', () => instantiateModules(document))

// MutationObserver
const config = { attributes: false, childList: true, subtree: true };

const callback = (mutationList) => {
  for (const mutation of mutationList) {
    if (mutation.addedNodes.length > 0) {
      mutation.addedNodes.forEach((node) => {
        instantiateModules(node);
      })
    }
  }
};

const observer = new MutationObserver(callback);
observer.observe(document, config);

/*
  Usage:
  ======

  html
  ----
  <button data-module="disappear">disappear!</button>

  js
  --
  // modules/disappear.js
  export default class Disappear {
    constructor(el) {
      el.style.display = 'none'
    }
  }
*/
