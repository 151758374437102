var hubInstagram = (function($) {
    var self = this;

    var settings = {
        container: '#instafetch'
    };

    var init = function() {
      if (!$(settings.container).length) {
        return;
      }

      instafetch.init({
        accessToken: '8629019204.1677ed0.ddaf5e3e9c40436896c7359afa9887b9',
        target: 'instafetch',
        numOfPics: 3,
        caption: false
      });


    };
     return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    hubInstagram.init();
});
