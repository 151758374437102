// Better VH for mobile viewports (https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
var vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', vh+'px');


var projectFilter = (function($) {
    var self = this;

    var init = function() {
      if (!$('.js-project-filter-education').length) {
        return;
      }

      $('.js-project-filter-education').click(function(e) {
        $('.parent-cat-57').addClass("vanish");
        $('.parent-cat-56').removeClass("vanish");
        $('.parent-cat-56 a').removeClass("vanish");
      });

      $('.js-project-filter-ffe').click(function(e) {
        $('.parent-cat-56').addClass("vanish");
        $('.parent-cat-57').removeClass("vanish");
        $('.parent-cat-57 a').removeClass("vanish");
      });


      $('.js-project-hero-education').click(function(e) {
        e.preventDefault();


        var filter = 'education';

        $.get("/projects-loadmore/?filter="+filter,function(data){
            var elems = $.parseHTML( data );
            if (elems.length == 0) {
                return;
            }
            $(".js-projects-listing").html("");
            $(".js-projects-listing").append(data);
        },'html')
        .done(function() {
            $(".js-projects-loading").removeClass('active');
            $(".js-projects-listing").removeClass('active');


            if(window.history) {
              history.pushState('');
            }
        })
        .fail(function() {
            console.log( "There was an issue connecting to the server. Sorry!" );
        });



        $('body').removeClass('active');
        $('body').addClass('downscroll');
        $('body').addClass('in-projects-filter');
        $('.js-project-hero-education').addClass("full-screen-hero");
        $('.js-project-hero-ffe').addClass("vanish");
        $('.js-project-filter-education').addClass("active");
        $('.landing-page-halves').addClass("swipe-upward");
        $('.parent-cat-57').addClass("vanish");
        $('.parent-cat-56').removeClass("vanish");
      });

      $('.js-project-hero-ffe').click(function(e) {
        e.preventDefault();

        var filter = 'ffe';
        var learning = 'learning';

        $.get("/projects-loadmore/?filter="+learning,function(data){
            var elems = $.parseHTML( data );
            // console.log(elems);
            if (elems.length == 0) {
                return;
                
            }
            // console.log(data);
            $(".js-projects-listing").html("");
            $(".js-projects-listing").append(data);
        },'html')
        .done(function() {
            $(".js-projects-loading").removeClass('active');
            $(".js-projects-listing").removeClass('active');

            if(window.history) {
              history.pushState('');
            }
        })
        .fail(function() {
            console.log( "There was an issue connecting to the server. Sorry!" );
        });

        $('body').removeClass('active');
        $('body').addClass('downscroll');
        $('body').addClass('in-projects-filter');
        $('.js-project-hero-ffe').addClass("full-screen-hero");
        $('.js-project-hero-education').addClass("vanish");
        $('.js-project-filter-ffe').addClass("active");
        $('.landing-page-halves').addClass("swipe-upward");
        $('.parent-cat-57').removeClass("vanish");
        $('.parent-cat-56').addClass("vanish");
        $('.project-filter-5').addClass("active");
      });

    }

    return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    projectFilter.init();
});
