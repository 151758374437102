function renderFaceliftHubCard(iopost) {
  return `
    <a class="hub-card-facelift" href="${iopost.link}">
      <img class="hub-card-facelift__image" src="${iopost.featured_image_src}">

      <div class="hub-card-facelift__category">IO</div>

      <h3 class="hub-card-facelift__title">${iopost.title.rendered}</h3>
    </a>
  `
}

function renderLegacyHubCard(iopost) {
  return `
    <div class="col col-lg-4">
      <a class="hub-card" href="${iopost.link}">
        <div class="hub-card__inner">
          <div class="hub-card__image hub-card__image--hmpg">
            <img src=\"${iopost.featured_image_src}\">
          </div>

          <div class="hub-card__content">
            <div class="hub-card__category">
              <span class="btn--small-border-radius" style="background:#ffffff; color: #0a0a0a" href="https://io.education/inside-out-categories/insight/">IO</span>
            </div>

            <div class="hub-card__text">
              <p class="h3">${iopost.title.rendered}</p>
            </div>
          </div>
        </div>
      </a>
    </div>`
}

function loadIOPosts() {
  const ioPostContainers = document.getElementById('io-posts') ? [document.getElementById('io-posts')] : Array.from(document.querySelectorAll('.io-posts__container'));

  if (ioPostContainers.length > 0) {
    const request = new XMLHttpRequest();

    request.open('GET', 'https://io.education/wp-json/wp/v2/hub', true);

    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        const ioposts = JSON.parse(this.responseText);

        ioPostContainers.forEach(elContainer => {
          const isFaceLift = elContainer.getAttribute('data-facelift');
          elContainer.innerHTML = "";

          ioposts.slice(0, 3).forEach(iopost => {
            if (isFaceLift) {
              elContainer.innerHTML += renderFaceliftHubCard(iopost);
            } else {
              elContainer.innerHTML += renderLegacyHubCard(iopost);
            }
          })
        })
      }
    };

    request.onerror = function () {
      console.log('CONNECTION ERROR')
    };

    request.send();
  }
}

document.addEventListener('DOMContentLoaded', loadIOPosts);