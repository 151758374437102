/* Requires: smooth-scroll.min.js */

var backToTop = (function($) {
    var self = this;
    
    var settings = {
        container: '.js-btt'
    };

    var init = function() {
        _backtotop();
    };

        var _backtotop = function() {
            $(window).scrollTop(0);
        }

     return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    backToTop.init();
});