/* Requires: smooth-scroll.min.js */

var keyStats = (function($) {
    var self = this;

    var settings = {
        container: '.js-keystats'
    };

    var init = function() {
      if (!$(settings.container).length) {
        return;
      }

      var beforeData = $(settings.container).data('before');
      var afterData = $(settings.container).data('after');
      var figuresData = $(settings.container).data('figures');
      var labelsData = $(settings.container).data('labels');
      var loopCount = 0;
      var numFigures = beforeData.length;
      var options = {
        useEasing: true,
        useGrouping: true
      };

      var countUp = new CountUp('keyStatFigure', 0, figuresData[loopCount], 0, 2.5, options);
      $(settings.container).find('.before').text(beforeData[loopCount]);
      $(settings.container).find('.after').text(afterData[loopCount]);

      var countUpComplete = function() {
        $(settings.container).find('.label').text(labelsData[loopCount]).addClass('show');

        loopCount++;
        if(numFigures == loopCount) {
          loopCount = 0;
        }


        setTimeout(function(){
          $(settings.container).find('.label').removeClass('show', function() {
            $(settings.container).find('.label').text("");
          });

        }, 4500);

        setTimeout(function(){
          $(settings.container).find('.before').text(beforeData[loopCount]);
          $(settings.container).find('.after').text(afterData[loopCount]);
          countUp.update(figuresData[loopCount]);

        }, 5000);
      }

      if (!countUp.error) {
        countUp.start(countUpComplete);

      } else {
        console.error(countUp.error);
      }

    };
     return {
        init: init
    };

})(jQuery);

$(document).ready(function($) {
    keyStats.init();
});
