class CardCarousel {
  constructor(el) {
    this.elsCards = Array.from(el.querySelectorAll('.card-carousel__card'))
    this.elsControls = Array.from(el.querySelectorAll('.card-carousel__controls__button'))
    this.elProgressBar = el.querySelector('.card-carousel__controls__progress-bar')
    this.slideDuration = 2
    this.slideInterval = 5

    if (this.elsCards.length < 2) {
      return
    }

    this.mm = gsap.matchMedia()
    this.currentIndex = 0
    this.slideTl = null
    this.progressTl = null


    this.addControlsListeners()
    this.addVisibilityListener()
    this.startCarousel()
  }

  addControlsListeners() {
    this.elsControls.forEach((el, index) => {
      el.addEventListener('click', async () => {
        this.stopCarousel()
        await this.goTo(index, true)
        this.startCarousel()
      })
    })
  }

  addVisibilityListener() {
    document.addEventListener('visibilitychange', (e) => {
      if (document.hidden) {
        this.stopCarousel()
      } else {
        this.startCarousel()
      }
    })
  }

  startCarousel() {
    this.carouselInterval = setInterval(() => {
      this.goToNext()
    }, this.slideInterval * 1000)
    this.goToProgess(false)
  }

  stopCarousel() {
    clearInterval(this.carouselInterval)
  }

  goToNext() {
    const nextSlide = (this.currentIndex + 1) % this.elsCards.length
    this.goTo(nextSlide, false)
  }

  async goTo(index, immediate) {
    if (this.currentIndex === index) {
      return
    }

    if (this.slideTl) {
      await this.slideTl
    }

    const currentCard = this.elsCards[this.currentIndex]
    const nextCard = this.elsCards[index]

    this.mm.add('(prefers-reduced-motion: reduce)', () => {
      this.slideTl = gsap.timeline()
        .add(this.leaveCard(currentCard, true))
        .add(this.enterCard(nextCard, true), 0)
    })

    this.mm.add('(prefers-reduced-motion: no-preference)', () => {
      this.slideTl = gsap.timeline()
        .add(this.leaveCard(currentCard, false))
        .add(this.enterCard(nextCard, false), 0)
    })

    this.currentIndex = index
    return this.goToProgess(immediate)
  }

  getAnimationComponents(elCard, reduceMotion) {
    const elContent = elCard.querySelector('.card-carousel__content-box')
    const elImage = elCard.querySelector('.card-carousel__image')
    const ease = reduceMotion ? 'none' : 'power2.inOut'
    const duration = reduceMotion ? this.slideDuration * 0.75 : this.slideDuration

    return { elContent, elImage, ease, duration }
  }

  leaveCard(elCard, reduceMotion = false) {
    const { elContent, elImage, ease, duration } = this.getAnimationComponents(elCard, reduceMotion)

    const tl = gsap.timeline({ defaults: { ease, duration } })

    if (reduceMotion) {
      tl.fromTo(elImage, { opacity: 1 }, { opacity: 0 })
    } else {
      tl.fromTo(elImage, { xPercent: 0 }, { xPercent: -100 })
    }
    tl.fromTo(elContent, { autoAlpha: 1 }, { autoAlpha: 0, duration: this.slideDuration * 0.75 }, 0)
    tl.set(elCard, { autoAlpha: 0 })

    return tl
  }

  enterCard(elCard, reduceMotion = false) {
    const { elContent, elImage, ease, duration } = this.getAnimationComponents(elCard, reduceMotion)

    const tl = gsap.timeline({ defaults: { ease, duration } })
      .set(elCard, { clearProps: true })
      .set(elCard, { autoAlpha: 1 })

    if (reduceMotion) {
      tl.fromTo(elImage, { opacity: 0 }, { opacity: 1 })
    } else {
      tl.fromTo(elImage, { xPercent: 100 }, { xPercent: 0 })
    }

    tl.fromTo(elContent, { autoAlpha: 0 }, { autoAlpha: 1, duration: this.slideDuration * 0.75 }, 0)

    return tl
  }

  goToProgess(immediate) {
    const ease = immediate ? 'power2.out' : 'none'
    const duration = immediate ? this.slideDuration : this.slideInterval

    if (this.progressTl) {
      this.progressTl.kill()
    }

    const prevScaleX = this.currentIndex / this.elsCards.length < 1 ? this.currentIndex / this.elsCards.length : 0
    const scaleX = (this.currentIndex + 1) / this.elsCards.length

    if (immediate) {
      this.progressTl = gsap.to(
        this.elProgressBar,
        { scaleX: prevScaleX, duration, ease }
      )
    } else {
      this.progressTl = gsap.fromTo(
        this.elProgressBar,
        { scaleX: prevScaleX },
        { scaleX, duration, ease }
      )
    }

    return this.progressTl
  }
}
